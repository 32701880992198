// import * as XLSX from 'xlsx';
import provincesJson from '@/assets/json/provinces.json';
import citiesJson from '@/assets/json/cities.json';
import areasJson from '@/assets/json/areas.json';
import CITY_DATA from '@/assets/json/pc-code.json';
import pinyin from 'pinyin';
import dayjs from 'dayjs';
import { isJSON } from './isType';
import { isString } from 'lodash';
import { isArray } from 'lodash';

/**
 * 监听键盘ctrl+alt + 鼠标滚轮控制放大缩小事件
 * event 事件
 * enlarge 放大函数
 * shrink 缩小函数
 */
export function zoomWithMouse(event: any, enlarge: any, shrink: any) {
  if (event.ctrlKey && event.altKey) {
    if (event.deltaY > 0) {
      // 鼠标滚轮向后
      // 缩小页面的操作
      // 可以使用合适的缩小逻辑
      // 例如使用 document.body.style.zoom 或者改变元素的宽高等
      shrink();
    } else if (event.deltaY < 0) {
      // 鼠标滚轮前
      // 放大页面的操作
      // 可以使用合适的缩小逻辑
      // 例如使用 document.body.style.zoom 或者改变元素的宽高等
      enlarge();
    } else event.preventDefault(); // 阻止默认的滚动行为
  }
}

/**
 * 监听键盘ctrl+alt + 键盘+ - 号控制放大缩小事件
 * event 事件
 * enlarge 放大函数
 * shrink 缩小函数
 */
export function zoomWithPlusAndMinus(event: any, enlarge: any, shrink: any) {
  if (event.ctrlKey && event.altKey) {
    if (event.key === '+') {
      // 放大页面的操作
      // 可以使用合适的放大逻辑
      // 例如使用 document.body.style.zoom 或者改变元素的宽高等
      enlarge();
    } else if (event.key === '-') {
      // 缩小页面的操作
      // 可以使用合适的缩小逻辑
      // 例如使用 document.body.style.zoom 或者改变元素的宽高等
      shrink();
    } else event.preventDefault(); // 阻止默认的滚动行为
  }
}

// 时间戳转换
export function formatDate(cellValue: string | number) {
  if (cellValue === null || cellValue === '') return '';
  const date = new Date(cellValue);
  const year = date.getFullYear();
  const month =
    date.getMonth() + 1 < 10 ? `0${date.getMonth()}` : date.getMonth() + 1;
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  const hours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
  const minutes =
    date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
  const seconds =
    date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds();
  // return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

// 将时间戳转换为标准时间
export function timeSwitch(time: number) {
  if (String(time).split('').length < 13) time *= 1000;
  time = Number(formatDate(time));
  return time;
}

// 获取当前的时间，年月日
export function getNowDate() {
  const date = new Date();
  const sign2 = ':';
  const year = date.getFullYear(); // 年
  let month: number | string = date.getMonth() + 1; // 月
  let day: number | string = date.getDate(); // 日
  // let hour = date.getHours(); // 时
  // let minutes = date.getMinutes(); // 分
  // let seconds = date.getSeconds() //秒
  const weekArr = [
    '星期一',
    '星期二',
    '星期三',
    '星期四',
    '星期五',
    '星期六',
    '星期天'
  ];
  const week = weekArr[date.getDay()];
  // 给一位数的数据前面加 “0”
  if (month >= 1 && month <= 9) {
    month = `0${month}`;
  }
  if (day >= 0 && day <= 9) {
    day = `0${day}`;
  }
  // if (hour >= 0 && hour <= 9) {
  //   hour = "0" + hour;
  // }
  // if (minutes >= 0 && minutes <= 9) {
  //   minutes = "0" + minutes;
  // }
  // if (seconds >= 0 && seconds <= 9) {
  //   seconds = "0" + seconds;
  // }
  // return year + "-" + month + "-" + day + " " + hour + sign2 + minutes + sign2 + seconds;
  return `${year}${month}${day}`;
}

/* 
  treeData: 传进来的树结构
  hasChild: 处理后的数组是否存在children字段， true 存在 false 不存在
*/
// 将树形结构扁平化不保留children
export function flatTree(treeData: any, hasChild = false) {
  let result: any = [];
  treeData?.forEach((item: any) => {
    // 先克隆一份数据作为第一层级的填充
    const res = JSON.parse(JSON.stringify(item));
    if (!hasChild) delete res.children;
    result.push(res);
    if (item.children && item.children.length > 0) {
      // 如果当前children为数组并且长度大于0，才可进入flatTree()方法
      result = result.concat(flatTree(item.children, hasChild));
    }
  });
  return result;
}

/* 
  treeData: 传进来的树结构
  hasChild: 处理后的数组是否存在children字段， true 存在 false 不存在
*/
// 将树形结构扁平化且保留children
export function flatTreeWithChildren(treeData: any, hasChild = false) {
  let result: any = [];
  treeData.forEach((item: any) => {
    // 先克隆一份数据作为第一层级的填充
    const res = JSON.parse(JSON.stringify(item));
    // if (!hasChild) delete res.children;
    result.push(res);
    if (item.children && item.children.length > 0) {
      // 如果当前children为数组并且长度大于0，才可进入flatTree()方法
      result = result.concat(flatTreeWithChildren(item.children, hasChild));
    }
  });
  return result;
}

/**
 * 递归过滤节点，但保留原树结构，即符合条件节点的父路径上所有节点不管是否符合条件都保留
 * @param {Node[]} nodes 要过滤的树
 * @param {node => boolean} predicate 过滤条件，符合条件的节点保留(参数为函数，返回值为布尔值)
 * @param {node => boolean} wrapMatchFn 层级条件(参数为函数，返回值为布尔值)
 * @return 过滤后的包含根节点数组
 */
export const filterSearchTree = (
  nodes: any,
  predicate: any,
  wrapMatchFn = () => false
) => {
  // 如果已经没有节点了，结束递归
  if (!(nodes && nodes.length)) {
    return [];
  }
  const newChildren = [];
  for (let i = 0; i < nodes.length; i++) {
    const node: any = nodes[i];
    // 想要截止匹配的那一层（如果有匹配的则不用递归了，直接取下面所有的子节点）
    if (wrapMatchFn(node) && predicate(node)) {
      newChildren.push(node);
      continue;
    }
    const subs = filterSearchTree(node.children, predicate, wrapMatchFn);

    // 以下两个条件任何一个成立，当前节点都应该加入到新子节点集中
    // 1. 子孙节点中存在符合条件的，即 subs 数组中有值
    // 2. 自己本身符合条件
    if ((subs && subs.length) || predicate(node)) {
      node.children = subs || [];
      newChildren.push(node);
    }
  }
  return newChildren.length ? newChildren : [];
};

// 重新组合树数据(根据需要来重组树结构中的属性字段，用来新增判断是否为叶子节点信息)
export const dealTreeData = (treeData) => {
  const data = treeData.map((item) => ({
    ...item,
    // 新增title字段
    title: item.name,
    // 新增叶子节点字段
    isLeaf: item.children ? false : true,
    // 如果children为空数组，则置为null
    children:
      item.children && item.children.length ? dealTreeData(item.children) : null
  }));
  return data;
};

// 重组树形数据所需子方法
export const controldataTreeFilter = (treeData: any) => {
  // const temp = treeData.map((item2: any) => ({
  //   [item2.code]: item2.value
  // }));
  // let obj = {};
  // temp.forEach((item: any) => {
  //   obj = { ...obj, ...item };
  // });
  const temp = treeData.map((item2: any) => ({
    [item2.ctlCode]: parseJsonString(item2.ctlValue)
  }));
  let obj = {};
  temp.forEach((item: any) => {
    obj = { ...obj, ...item };
  });
  return obj;
};

/* 
重组树形属性数据并结合表头设置
  treeData: 传进来的树结构
  enable: 传进来的是否启用字段名，用于是否disabled选择
  level: 子树的层级
*/

export const dataTreeFilter = (
  treeData: any,
  enable: string | undefined = 'enable',
  level = 1,
  parentControlType = 1 //父级组织空编维度
) => {
  const tempLevel = level;
  const data = treeData.map((item: any, index1: any) => {
    let disabled = false;
    if (enable && item[enable] === 2) disabled = true;
    return {
      ...item,
      ...controldataTreeFilter(item.ctlValue),
      selectable: !disabled,
      disabled,
      level,
      parentControlType,
      children:
        item.children && item.children.length
          ? dataTreeFilter(
              item.children,
              enable,
              tempLevel + 1,
              item.controlType
            )
          : null
    };
  });
  return data;
};

export const dataFilter = (data: any) => {
  console.log(data);

  const arr = data.map((item: any, index1: any) => ({
    ...item,
    ...controldataTreeFilter(item.positionDetailData)
  }));
  return arr;
};

// 树形结构单条件模糊搜索
export const mapTree = (value, arr) => {
  const newarr: any = [];
  arr.forEach((element) => {
    if (element.title.indexOf(value) > -1) {
      // 判断条件
      newarr.push(element);
    } else {
      if (element.children && element.children.length > 0) {
        const redata = mapTree(value, element.children);
        if (redata && redata.length > 0) {
          const obj = {
            ...element,
            children: redata
          };
          newarr.push(obj);
        }
      }
    }
  });
  return newarr;
};

// 剪切地区代码，分成省市区
export const placeSplice = (placeCode: string) => {
  const province = placeCode.slice(0, 2); // 剪切前两位
  const city = placeCode.slice(0, 4); // 剪切前四位
  const area = placeCode.slice(); // 剪切全部
  if (!placeCode) return '';
  const { name: provinceName }: any = provincesJson.find(
    (item: any) => item.code === province
  );

  const { name: cityName }: any = citiesJson.find(
    (item: any) => item.code === city
  );

  // 如果是省市区则placeCode为6
  if (placeCode.length === 6) {
    const { name: areaName }: any = areasJson.find(
      (item: any) => item.code === area
    );
    return `${provinceName}${cityName}${areaName}`;
  }

  return `${provinceName}${cityName}`;
};

/* 
  导出或下载excel文件
  imcomeFile 传进来的file文件
  name 导出文件的名称
*/
export const downloadExcelFile = (imcomeFile: any, name: string) => {
  const file = new Blob([imcomeFile], { type: 'application/xlsx' });
  console.log('file', file, imcomeFile);
  // const fileName = 'filename.xlsx';
  // if ('download' in document.createElement('a')) {
  //   // 非IE下载
  //   const link = document.createElement('a');
  //   link.download = fileName;
  //   link.style.display = 'none';
  //   link.href = URL.createObjectURL(file);
  //   document.body.appendChild(link);
  //   link.click();
  //   URL.revokeObjectURL(link.href); // 释放URL 对象
  //   document.body.removeChild(link);
  // } else {
  //   // IE10+下载
  //   navigator.msSaveBlob(file);
  // }

  const changeUrl = window.webkitURL.createObjectURL(file);
  let downloadElement: any = document.createElement('a');
  downloadElement.download = `${name}.xlsx`;
  downloadElement.href = changeUrl;
  downloadElement.click();
  downloadElement = undefined;
  window.URL.revokeObjectURL(changeUrl);
};

// 扁平化含有children的数组
export function flattenArrayWithChildren(arr: any[]) {
  return arr.reduce((flatArray: any[], item: any) => {
    if (item.children) {
      flatArray.push(item);
      flatArray.push(...flattenArrayWithChildren(item.children));
    } else {
      flatArray.push(item);
    }
    return flatArray;
  }, []);
}

// 转换人员列表，将人员列表按名字首字母排列， 其中数字和emoji及其他特殊符号在数组最后
export function sortContactsForGroupChat(contacts: Array<any>): Array<any> {
  // 比较名称
  function getNameForComparison(name: string): string {
    if (/^\d/.test(name) || /^[\uD800-\uDBFF]/.test(name)) {
      return '#';
    }
    const pinyinName = pinyin(name, { style: pinyin.STYLE_NORMAL });
    return pinyinName[0][0].toUpperCase();
  }

  // 获取首字母， 数字和emoji表情首位的都放在#的数组里面
  function getFirstLetter(name: string): string {
    if (/^\d/.test(name) || /^[\uD800-\uDBFF]/.test(name)) {
      return '#';
    }
    const pinyinName = pinyin(name, { style: pinyin.STYLE_FIRST_LETTER });
    return pinyinName[0][0].toUpperCase();
  }

  // nickname > username > name
  const sortedContacts = contacts.sort((a, b) => {
    const nameA = getNameForComparison(
      a.nickname ? a.nickname : a.username ? a.username : a.name
    );
    const nameB = getNameForComparison(
      a.nickname ? a.nickname : a.username ? a.username : a.name
    );

    // 如果其中一个姓名以数字或 emoji 开头，则将其放在后面
    if (
      (/^\d/.test(nameA) || /^[\uD800-\uDBFF]/.test(nameA)) &&
      !(/^\d/.test(nameB) || /^[\uD800-\uDBFF]/.test(nameB))
    ) {
      return 1;
    }
    if (
      (/^\d/.test(nameB) || /^[\uD800-\uDBFF]/.test(nameB)) &&
      !(/^\d/.test(nameA) || /^[\uD800-\uDBFF]/.test(nameA))
    ) {
      return -1;
    }

    return nameA.localeCompare(nameB);
  });

  const groupedContacts = {};
  sortedContacts.forEach((contact) => {
    const firstLetter = getFirstLetter(contact.name);
    if (!groupedContacts[firstLetter]) {
      groupedContacts[firstLetter] = [];
    }
    groupedContacts[firstLetter].push(contact);
  });

  // 将 # 分组放在最后
  const sortedGroupedContacts = {};
  const keys = Object.keys(groupedContacts).sort();
  const index = keys.indexOf('#');
  if (index !== -1) {
    keys.splice(index, 1);
    keys.push('#');
  }
  keys.forEach((letter) => {
    sortedGroupedContacts[letter] = groupedContacts[letter];
  });

  // 将联系人对象转换为数组
  const sortedGroupedContactsArray = Object.entries(sortedGroupedContacts).map(
    ([letter, contacts]) => ({
      letter,
      contacts
    })
  );
  return sortedGroupedContactsArray;
}

/**
 *比较相差的分钟数
 * @param date1 时间1
 * @param date2 时间2
 * @param thresholdType 相差类型
 * @param thresholdNum 相差时间
 * @returns boolean
 */
export function compareTime(
  date1: any,
  date2: any,
  thresholdType: 'day' | 'hour' | 'minute' | 'second',
  thresholdNum: number
) {
  date1 = dayjs(date1).format('YYYY-MM-DD HH:mm:ss');
  date2 = dayjs(date2).format('YYYY-MM-DD HH:mm:ss');
  const diffMinutes = Math.abs(dayjs(date1).diff(dayjs(date2), 'minute'));
  return diffMinutes > thresholdNum;
}
// 树形结构多条件模糊搜索
// export const matchTreeData = (arr, searchCon) => {
//   const newArr: any = [];
//   const searchNameList = ['name', 'code', 'title'];
//   arr.forEach((item: any) => {
//     for (let i = 0, len = searchNameList.length; i < len; i++) {
//       const nameKey = searchNameList[i];
//       if (item.hasOwnProperty(nameKey)) {
//         if (item[nameKey] && item[nameKey].indexOf(searchCon) !== -1) {
//           newArr.push(item);
//           break;
//         } else {
//           if (item.childList && item.childList.length > 0) {
//             const resultArr = this.matchTreeData(item.childList, searchCon);
//             if (resultArr && resultArr.length > 0) {
//               newArr.push({
//                 ...item,
//                 childList: resultArr
//               });
//               break;
//             }
//           }
//         }
//       } else {
//         continue;
//       }
//     }
//   });
//   return newArr;
// };

// 导出excel
/*
    * @description:
    * @param {Object} json 服务端发过来的数据
    * @param {String} name 导出Excel文件名字

    * @param {String} titleArr 导出Excel表头

    * @param {String} sheetName 导出sheetName名字
    * @return:
    */
// export function exportExcel(json, name, titleArr, sheetName) {
//   /* convert state to workbook */
//   console.log(json);

//   let data = new Array();
//   let keyArray = new Array();
//   const getLength = function (obj) {
//     let count = 0;
//     for (let i in obj) {
//       if (obj.hasOwnProperty(i)) {
//         count++;
//       }
//     }
//     return count;
//   };
//   for (const key1 in json) {
//     // console.log(key1 + 'a');

//     if (json.hasOwnProperty(key1)) {
//       const element = json[key1];
//       let rowDataArray = new Array();
//       for (const key2 in element) {
//         if (element.hasOwnProperty(key2) && key2 !== 'id') {
//           const element2 = element[key2];
//           rowDataArray.push(element2);
//           if (keyArray.length < getLength(element)) {
//             keyArray.push(key2);
//           }
//         }
//       }
//       data.push(rowDataArray);
//     }
//   }
//   // keyArray为英文字段表头
//   // data.splice(0, 0, keyArray,titleArr);
//   data.splice(0, 0,titleArr);
//   console.log('data', data);
//   const ws = XLSX.utils.aoa_to_sheet(data);
//   const wb = XLSX.utils.book_new();
//   // 此处隐藏英文字段表头
//   // let wsrows = [{ hidden: true }];
//   // ws['!rows'] = wsrows; // ws - worksheet
//   XLSX.utils.book_append_sheet(wb, ws, sheetName);
//   /* generate file and send to client */
//   XLSX.writeFile(wb, name + '.xlsx');
// }
/**
 * 获取试用期考核状态名称
 * @param type
 * @returns
 */
export function getProbationExamineStatusName(type: number) {
  switch (type) {
    case 1:
      return '待创建';
    case 2:
      return '待提交';
    case 3:
      return '待确认';
    case 4:
      return '已退回';
    case 5:
      return '已生效';
    case 6:
      return '变更中';
    case 7:
      return '变更中';
    case 8:
      return '待自评';
    case 9:
      return '待评分';
    case 10:
      return '结果待确认';
    case 11:
      return '已确认';
    case 12:
      return '结果拒绝';
    case 13:
      return '结果拒绝';
    case 14:
      return '方案不通过';
    case 15:
      return '结果申诉';
    case 16:
      return '已结束';
    case 17:
      return '已结束';
    case 18:
      return '申诉超时';
    case 19:
      return '已生效';
    case 20:
      return '已生效';
    default:
      return '无状态';
  }
}

/**
 * 将颜色变浅
 * @param color 颜色(例如#cccccc)
 * @param percent 百分比(变浅的百分比，例如20)
 */
export function lightenColor(color = '#fff', percent) {
  const num = parseInt(color.slice(1), 16);
  const amt = Math.round(2.55 * percent);
  let r = (num >> 16) + amt;
  let b = ((num >> 8) & 0x00ff) + amt;
  let g = (num & 0x0000ff) + amt;
  r = Math.min(r, 255);
  g = Math.min(g, 255);
  b = Math.min(b, 255);
  const newColor =
    '#' + (g | (b << 8) | (r << 16)).toString(16).padStart(6, '0');
  return newColor;
}
// 格式化时间 用于工作台日历待办（仅限开始时间）
export function formatTimeDate(today, startTime) {
  today = dayjs(today);
  const start = dayjs(startTime);
  if (start.isSame(today, 'day')) {
    return `今日${start.format('HH:mm')}`;
  } else {
    return `${start.format('YYYY-MM-DD HH:mm')}`;
  }
}

// yyyy-mm-dd hh:mm 转时间戳 运算后在变回 yyyy-mm-dd hh:mm
export function formatNum(startDate: string, min: number) {
  let time = new Date(startDate).getTime();
  time -= min * (1000 * 60);
  const date = new Date(time);
  const year = date.getFullYear();
  const month =
    date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  const hours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
  const minutes =
    date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
  // return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
  return `${year}-${month}-${day} ${hours}:${minutes}`;
}

// 计算时间差 返回分钟
export function timeDiffMin(startDate: string, remindDate: string): number {
  let diff = new Date(startDate).getTime() - new Date(remindDate).getTime();
  return Number((diff / 60000).toFixed(0));
}

// 格式化时间，用于工作台日历待办
export function formatTimeRange(today, startTime, endTime) {
  today = dayjs(today);
  const start = dayjs(startTime);
  const end = dayjs(endTime);
  if (start.isSame(end, 'minute')) {
    return `${start.format('YYYY-MM-DD HH:mm')}`;
  }

  if (start.isSame(today, 'day') && end.isSame(today, 'day')) {
    if (start.isSame(end, 'minute')) {
      return `${start.format('HH:mm')}`;
    } else {
      return `今日${start.format('HH:mm')}~今日${end.format('HH:mm')}`;
    }
  } else if (start.isSame(today, 'day')) {
    return `今日${start.format('HH:mm')}~${end.format('YYYY-MM-DD HH:mm')}`;
  } else if (end.isSame(today, 'day')) {
    return `${start.format('YYYY-MM-DD HH:mm')}~今日${end.format('HH:mm')}`;
  }
  // else if (start.isSame(end, 'day') && start.isSame(today, 'day')) {
  //   return `今日${start.format('HH:mm')}~今日${end.format('HH:mm')}`;
  // }
  else {
    return `${start.format('YYYY-MM-DD HH:mm')}~${end.format(
      'YYYY-MM-DD HH:mm'
    )}`;
  }
}

// 根据身份证计算年龄
export function getAgeFromIdCard(idCard) {
  // 假设身份证号码中出生日期的格式为yyyymmdd
  const birthYear = Number(idCard.substring(6, 10));
  const birthMonth = Number(idCard.substring(10, 12)) - 1; // 月份从0开始计数
  const birthDay = Number(idCard.substring(12, 14));

  const today = new Date();
  const birthDate = new Date(birthYear, birthMonth, birthDay);

  let age = today.getFullYear() - birthDate.getFullYear();
  if (
    today.getMonth() < birthDate.getMonth() ||
    (today.getMonth() === birthDate.getMonth() &&
      today.getDate() < birthDate.getDate())
  ) {
    age--;
  }
  return age;
}

//base64转blob
export const base64ToBlob = (code) => {
  const parts = code.split(';base64,');
  const contentType = parts[0].split(':')[1];
  const raw = window.atob(parts[1]);
  const rawLength = raw.length;
  const uInt8Array = new Uint8Array(rawLength);
  for (let i = 0; i < rawLength; ++i) {
    uInt8Array[i] = raw.charCodeAt(i);
  }
  return new Blob([uInt8Array], { type: contentType });
};
/**
 * 根据code获取区域名称，如：北京市/东城区
 */
export const getRegionNameByCode = (
  code: string,
  regions: any = CITY_DATA
): any => {
  if (!regions?.length) return;
  // 遍历所有地区
  for (let region of regions) {
    // 如果当前地区的code匹配传入的code
    if (region.code == code) {
      // 如果没有children属性，直接返回name
      if (!region.children) {
        return region.name;
      } else {
        // 如果有children属性，递归调用自身并拼接name
        return `${region.name}/${getRegionNameByCode(code, region.children)}`;
      }
    }
    // 如果没有匹配，继续遍历children（如果有的话）
    if (region.children) {
      const childMatch = getRegionNameByCode(code, region.children);
      // 如果在children中找到了匹配，返回拼接后的结果
      if (childMatch) {
        return `${region.name}/${childMatch}`;
      }
    }
  }
  // 如果没有找到匹配的code，返回null或空字符串
  return null;
};
/**
 * 控件值转JSON字符串
 * @param variable
 * @returns
 */
export function convertToJsonString(variable: any) {
  if (variable === null || variable === void 0) return;
  try {
    // 使用JSON.stringify将变量转换为JSON字符串
    if (typeof variable === 'object') return JSON.stringify(variable);
    return variable.toString();
  } catch (error) {
    //
  }
}
/**
 * 控件值JSON字符串解析
 * @param jsonString
 * @returns
 */
export function parseJsonString(jsonString: string) {
  if (jsonString === void 0 || jsonString === null || jsonString === '') return;
  if (isJSON(jsonString)) return JSON.parse(jsonString);
  if (Number(jsonString) >= 0 || Number(jsonString) <= 0)
    return Number(jsonString);
  return jsonString;
}
/**
 * 扁平化树结构成列表
 * @param tree
 * @param result
 * @returns
 */
export function flattenTree(tree: any, result: any[] = []) {
  if (!isArray(tree)) return result;
  for (let item of tree) {
    result.push(item);
    if (item.children) {
      flattenTree(item.children, result);
    }
  }
  return result;
}
/**
 * 例：xxx公司/xxxx部门
 * @param data
 * @param targetId
 * @returns xxx公司/xxxx部门
 */
export function findPathById(data: any, targetId: any) {
  // 递归函数，用于遍历子节点并找到路径
  function traverse(items: any, parentId: any, path: any): any {
    const temp = path ? `${path}/` : '';
    for (let item of items) {
      if (item.id === targetId) {
        // 如果找到目标ID，返回当前路径
        return `${temp}${item.name}`;
      }
      if (item.children) {
        // 如果当前节点有子节点，递归调用traverse
        const childPath = traverse(
          item.children,
          item.id,
          `${temp}${item.name}`
        );
        if (childPath) {
          // 如果在子节点中找到目标，返回路径
          return childPath;
        }
      }
    }
    // 如果没有找到目标，返回null
    return null;
  }

  // 调用traverse函数开始遍历，初始路径为空字符串
  return traverse(data, null, '');
}

// 日期对 时间戳加减
export function calTime(time: string, temp: number) {
  return new Date(new Date(time).getTime() + temp).toISOString();
}

// 两个日期加减 返回时间戳
export function calTimeStamp(day1: string, day2: string) {
  return new Date(day1).getTime() - new Date(day2).getTime();
}
/**
 * 津贴类型1节日2学历3技能4职称5采暖6高温补贴7生育8司龄9工龄10餐费11交通12通讯13生日14特殊15外派16夜班17住房18自定义
 */
export const subsidyTypeList = [
  { value: 1, label: '节日', label2: '节日补贴' },
  { value: 2, label: '学历类型', label2: '学历补贴' },
  { value: 3, label: '技能等级', label2: '技能补贴' },
  { value: 4, label: '职称', label2: '职称补贴' },
  { value: 5, label: '采暖', label2: '采暖补贴' },
  { value: 6, label: '高温', label2: '高温补贴' },
  { value: 7, label: '生育', label2: '生育补贴' },
  { value: 8, label: '司龄', label2: '司龄补贴' },
  { value: 9, label: '工龄', label2: '工龄补贴' },
  { value: 10, label: '餐费', label2: '餐费补贴' },
  { value: 11, label: '交通', label2: '交通补贴' },
  { value: 12, label: '通讯', label2: '通讯补贴' },
  { value: 13, label: '生日', label2: '生日补贴' },
  { value: 14, label: '特殊', label2: '特殊补贴' },
  { value: 15, label: '外派', label2: '外派补贴' },
  { value: 16, label: '夜班', label2: '夜班补贴' },
  { value: 17, label: '住房', label2: '住房补贴' },
  { value: 18, label: '自定义', label2: '自定义' }
];
/**
 * 人员在职类型
 * 1正式 2试用 3见习 4兼职 5实习 6劳务派遣 7返聘员工
 */
export const rosterTypeList = [
  {
    value: 1,
    label: '正式'
  },
  {
    value: 2,
    label: '试用'
  },
  {
    value: 3,
    label: '见习'
  },
  {
    value: 4,
    label: '兼职'
  },
  {
    value: 5,
    label: '实习'
  },
  {
    value: 6,
    label: '劳务派遣'
  },
  {
    value: 7,
    label: '返聘员工'
  }
];
/**
 * 扣发规则，适用群体
 */
export const bonusRangeRulesList = [
  {
    value: 1,
    label: '当月入职'
  },
  {
    value: 2,
    label: '当月离职'
  }
];
/**
 * 节假日类型
 */
export const festivalTypeList = [
  {
    value: 1,
    label: '元旦节日'
  },
  {
    value: 2,
    label: '妇女节'
  },
  {
    value: 3,
    label: '清明节'
  },
  {
    value: 4,
    label: '劳动节'
  },
  {
    value: 5,
    label: '儿童节'
  },
  {
    value: 6,
    label: '端午节'
  },
  {
    value: 7,
    label: '建党节'
  },
  {
    value: 8,
    label: '建军节'
  },
  {
    value: 9,
    label: '七夕节'
  },
  {
    value: 10,
    label: '中秋节'
  },
  {
    value: 11,
    label: '国庆节'
  },
  {
    value: 12,
    label: '春节'
  }
];
/**
 * 1年假2事假3短期病假4长期病假5产假6产检假7婚假8陪产假9丧假10工伤假11哺乳假12育儿假13探亲假
 */
export const leaveTypeList = [
  {
    value: 1,
    label: '年假'
  },
  {
    value: 2,
    label: '事假'
  },
  {
    value: 3,
    label: '短期病假'
  },
  {
    value: 4,
    label: '长期病假'
  },
  {
    value: 5,
    label: '产假'
  },
  {
    value: 6,
    label: '产检假'
  },
  {
    value: 7,
    label: '婚假'
  },
  {
    value: 8,
    label: '陪产假'
  },
  {
    value: 9,
    label: '丧假'
  },
  {
    value: 10,
    label: '工伤假'
  },
  {
    value: 11,
    label: '哺乳假'
  },
  {
    value: 12,
    label: '育儿假'
  },
  {
    value: 13,
    label: '探亲假'
  }
];
/**
 * 加班类型1工作日加班2休息日加班3节假日加班
 */
export const overtimeTypeList = [
  {
    value: 1,
    label: '工作日加班'
  },
  {
    value: 2,
    label: '休息日加班'
  },
  {
    value: 3,
    label: '节假日加班'
  }
];
export const otherDeductionRuleTypeList = [
  {
    value: 1,
    label: '旷工扣款规则'
  },
  {
    value: 2,
    label: '迟到扣款规则'
  },
  {
    value: 3,
    label: '早退扣款规则'
  },
  {
    value: 4,
    label: '综合扣款规则'
  }
];
export const otherDeductionMethodList = [
  {
    value: 1,
    label: '按迟到分钟数扣款'
  },
  {
    value: 2,
    label: '按迟到次数扣款'
  },
  {
    value: 3,
    label: '按固定金额扣款'
  }
];
export const otherDeductionMethodLeaveEarlyList = [
  {
    value: 1,
    label: '按早退分钟数扣款'
  },
  {
    value: 2,
    label: '按早退次数扣款'
  },
  {
    value: 3,
    label: '按固定金额扣款'
  }
];
/**
 * 计薪基数1基本工资2职位工资3职务工资4绩效工资5季度奖6全勤奖7推荐奖
 */
export const salaryBaseList = [
  {
    value: 1,
    label: '基本工资'
  },
  {
    value: 2,
    label: '职位工资'
  },
  {
    value: 3,
    label: '职务工资'
  },
  {
    value: 4,
    label: '绩效工资'
  },
  {
    value: 5,
    label: '季度奖'
  },
  {
    value: 6,
    label: '全勤奖'
  },
  {
    value: 7,
    label: '推荐奖'
  }
];
/**
 * 计薪基数1基本工资2职位工资3职务工资4绩效工资5季度奖
 */
export const salaryBaseList2 = [
  {
    value: 1,
    label: '基本工资'
  },
  {
    value: 2,
    label: '职位工资'
  },
  {
    value: 3,
    label: '职务工资'
  },
  {
    value: 4,
    label: '绩效工资'
  },
  {
    value: 5,
    label: '季度奖'
  }
];
/**
 * 折算标准1按月应出勤天数折算2月出勤+法定节假日3自定义
 */
export const salaryConversionList = [
  {
    value: 1,
    label: '按月应出勤天数折算'
  },
  {
    value: 2,
    label: '月出勤+法定节假日'
  },
  {
    value: 3,
    label: '自定义'
  }
];
/**
 * 1(1按工资的一定比例补偿2按固定金额补偿)2(1按分钟数2按次数3固定)3(1按分钟数2按次数3固定)4(1旷工2迟到3早退)
 */

/**
 * 根据等级工龄等发放金额的4种方式
 */
export const subsidySystem = [
  {
    value: 'C4333624452CD4CEF8CBE6D967F6B237E',
    label: '职称',
    tableTitle: '职称'
  },
  {
    value: 'C5B3625070EFD4698A20925CE32B31F16',
    label: '职级',
    tableTitle: '职级'
  },
  {
    value: 'CC14DBDACA73B441E84106FA586B08645',
    label: '司龄',
    tableTitle: '司龄(年)'
  },
  {
    value: 'C384D7E93F5834B4F9526534BF613A08D',
    label: '工龄',
    tableTitle: '工龄(年)'
  }
];
export function removeIdFields(data: any) {
  if (Array.isArray(data)) {
    // 如果是数组，遍历数组中的每个元素
    data.forEach((item, index) => {
      data[index] = removeIdFields(item); // 递归处理数组中的每个元素
    });
  } else if (typeof data === 'object' && data !== null) {
    // 如果是对象，遍历对象的每个属性
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        if (key === 'id') {
          // 如果属性是 id，则删除它
          delete data[key];
        } else {
          // 如果属性值是对象或数组，递归处理
          data[key] = removeIdFields(data[key]);
        }
      }
    }
  }
  // 如果是基本类型，不做处理
  return data;
}
